<template>
  <div>
    <!-- Modal  start-->
    <CModal
      :show.sync="showModal"
      :close-on-backdrop="false"
      centered
      title="Modal title 2"
      size="lg"
      color="primary"
    >
      <template #header>
        <h6 class="modal-title">{{ titleModal }}</h6>
        <CButtonClose @click="CloseModal()" class="text-white" />
      </template>
      <div>
        <CRow>
          <CCol md="6">
            <CInput
              label="Industry name"
              horizontal
              v-model="IndustryData.IndustryName"
            />
          </CCol>
          <CCol md="6">
            <div class="form-group form-row">
              <label class="col-form-label col-sm-3">Status</label>
              <CSwitch
                style="padding-top: 5px"
                color="info"
                shape="pill"
                labelOn="on"
                labelOff="off"
                :checked.sync="IndustryData.isActive"
              />
            </div>
            <!-- <div class="col-md-3">
                <label class="">Status</label>
              </div> -->
            <!-- <div class="col-md-9 pr-0">
                
              </div> -->
          </CCol>
        </CRow>
      </div>

      <template #footer>
        <CButton
          @click="SaveData('Save')"
          v-if="viewmode !== 'view'"
          color="primary"
        >
          Save
        </CButton>
        <CButton @click="CloseModal()" id="btnCancel" color="secondary">
          Cancel
        </CButton>
      </template>
    </CModal>
    <!-- Modal  end-->
  </div>
</template>
<style>
.c-switch-info .c-switch-input:checked + .c-switch-slider {
  background-color: #62e19c;
  border-color: #3cbd76;
}
.c-switch-info .c-switch-input:checked + .c-switch-slider::before {
  border-color: #3cbd76;
}
</style>
<script>
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import store from "../../store";
import axios from "axios";

var URL_Insert = `${store.getters.URL}/api/MasterIndustry/insert`;
var URL_Update = `${store.getters.URL}/api/MasterIndustry/update`;
const titleAdd ="Add Industry";
const titleEdit ="Edit Industry";

export default {
  name: "AddOrUpdateIndustryModal",
  components: {
    vSelect,
  },
  data() {
    return {
      userprofile: JSON.parse(localStorage.getItem("userprofile")),
      userToken: JSON.parse(localStorage.getItem("IdeationToken")),
      titleModal: titleAdd,
      IndustryData:{
        IndustryId: 0,
        IndustryName: null,
        isActive: true,
      },
    };
  },
  props: {
    showModal: Boolean,
    item: Object,
    viewmode: {
      type: String,
      default: "view",
      required: false
    }
  },
  computed: {
    isshow: {
      get() {
        return this.showModal;
      },
      set(v) {
        this.$emit("AddOrUpdateIndustryModal-updated", v);
      }
    },
  },
  mounted() {
  },
  methods: {
    setDefault() {
      this.IndustryData.IndustryId = 0;
      this.IndustryData.IndustryName = null;
      this.IndustryData.isActive = true;
    },
    CloseModal() {
      this.isshow = false;
      this.setDefault();
    },
    SaveData(action) {
      if (this.IndustryData.IndustryId > 0) {
        this.UpdateData();
      } else {
        this.AddData();
      }
    },
    AddData() {
      axios.post(URL_Insert, this.mapDTO(this.IndustryData))
      .then(res => {
        if (res.status === 200) {
          this.$_toast_Noti("success", "success", "");
          this.CloseModal();
          this.$emit("save-industry-success");
        }
      })
      .catch(err => {
        console.error("err insret Industry",err);
      });
    },
    UpdateData() {
      axios.put(URL_Update, this.mapDTO(this.IndustryData))
      .then(res => {
        if (res.status === 200) {
          this.$_toast_Noti("success", "success", "");
          this.CloseModal();
          this.$emit("save-industry-success");
        }
      })
      .catch(err => {
        console.error("err update Industry",err);
      });
    },
    mapDTO(item)
    {
      let param = {
        IndustryID: 0,
        IndustryDesc: null,
        IsActive: true
      };

      param.IndustryID = item.IndustryId;
      param.IndustryDesc = item.IndustryName;
      param.IsActive = item.isActive;

      return param;
    },
  },
  watch: {
    showModal: function (newVal, oldVal) {
      // console.log("showModal", newVal);
      // console.log("item", this.item);
      if (newVal) {
        let temp = this.item;
        if (temp != null) {
        this.titleModal = titleEdit;
        this.IndustryData.IndustryId = temp.IndustryID;
        this.IndustryData.IndustryName = temp.IndustryDesc;
        this.IndustryData.isActive = temp.IsActive;
      } else {
        this.titleModal = titleAdd;
        this.setDefault();
      }
      }
    },
  }
};
</script>
